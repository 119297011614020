// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { German } from 'flatpickr/dist/l10n/de.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    const locales = {
      de: German
    };

    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: locales,
      time_24hr: true
    }
  }
}
