import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="url"
export default class extends Controller {
  static values = {
    page: String,
    path: String,
  }

  connect() {
    window.history.pushState(window.history.state, this.pageValue, this.pathValue)
  }
}
