import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false
    })
    this.modal.show()

    addEventListener("turbo:before-stream-render", (e) => { this.beforeStreamRender(e) })
    addEventListener('hidden.bs.modal', (e) => { this.removeModals(e) })
  }

  beforeStreamRender(_event) {
    this.modal.hide()
  }

  removeModals(_event) {
    const attachmentModal = document.getElementById("attachmentModal")

    if (document.contains(attachmentModal)) {
      attachmentModal.remove();
    }
  }
}
