import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js';

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = ["form"]

  connect() {}

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
  }

  submit(event) {
    event.preventDefault();
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this._submit(event);
    }, 300)
  }

  _submit(event) {
    const csrfToken = document.querySelector("[name='csrf-token']").content

    post(event.target.form.action, {
      body: new FormData(event.target.form),
      responseKind: 'turbo-stream',
      headers: {
        'X-CSRF-Token': csrfToken
      },
    });
  }
}
